import { PaginationLink } from "./interfaces/Pagination";
import SelectItem from "./interfaces/SelectItem";

/**
 * Gets path position from url, if -1 is passed it returns the last position.
 * @param pos integer
 * @returns  null or string
 */
export function getPathPosition(pos: number): string | null {
  const pathArray = window.location.pathname.split("/");

  if (pos !== -1) {
    return pathArray[pos] || null;
  }

  return pathArray[pathArray.length - 1] || null;
}

export function getUrlSearchParam(urlParam: string): string {
  const urlParams = new URLSearchParams(window.location.search);
  const value = urlParams.get(urlParam);
  return value;
}

/**
 * Loads jsonp from url
 * @param url url to load json from
 * @param callback name of function to execute
 */
export function loadJSONP(url: string, callback: string): void {
  // Create script with url and callback (if specified)
  const ref = window.document.getElementsByTagName("script")[0];
  const script = window.document.createElement("script");
  script.src =
    url + (url.indexOf("?") + 1 ? "&" : "?") + "callback=" + callback;

  // Insert script tag into the DOM (append to <head>)
  ref.parentNode.insertBefore(script, ref);

  // After the script is loaded (and executed), remove it
  script.onload = function () {
    script.remove();
  };
}

/**
 * Gets mode
 * @returns string prod|dev
 */
export function getMode(): string {
  return process.env.NODE_ENV;
}

/**
 * Gets random integer
 *
 * @param min
 * @param max
 * @returns
 */
export function getRandomInt(min: number, max: number): number {
  min = Math.ceil(min);
  max = Math.floor(max);
  return Math.floor(Math.random() * (max - min) + min); //The maximum is exclusive and the minimum is inclusive
}

const shorDateFormat: Intl.DateTimeFormatOptions = {
  day: "numeric",
  year: "numeric",
  month: "short",
};
const standardDateTimeFormat: Intl.DateTimeFormatOptions = {
  day: "numeric",
  year: "numeric",
  month: "short",
  hour: "numeric",
  minute: "numeric",
};
const longDateTimeFormat: Intl.DateTimeFormatOptions = {
  day: "numeric",
  year: "numeric",
  month: "long",
  weekday: "long",
  hour: "numeric",
  minute: "numeric",
};
const longDateFormat: Intl.DateTimeFormatOptions = {
  day: "numeric",
  year: "numeric",
  month: "long",
  weekday: "long",
};
const monthShortFormat: Intl.DateTimeFormatOptions = { month: "short" };
const monthFormat: Intl.DateTimeFormatOptions = { month: "long" };
const yearFormat: Intl.DateTimeFormatOptions = { year: "numeric" };
const dayFormat: Intl.DateTimeFormatOptions = { day: "numeric" };
export type DateFormat = typeof DateFormat[keyof typeof DateFormat];
export const DateFormat = {
  short: shorDateFormat,
  standardDateTime: standardDateTimeFormat,
  longDateTime: longDateTimeFormat,
  shortMonth: monthShortFormat,
  day: dayFormat,
  longDate: longDateFormat,
  month: monthFormat,
  year: yearFormat,
};

/**
 * Format a date
 * @param date date to format
 * @param format DateFormat options (short, standardDateTime, longDateTime, month, day)
 * @returns formated date string
 */
export function formatDate(
  date: Date,
  format: DateFormat = DateFormat.standardDateTime
): string {
  return new Intl.DateTimeFormat("en", format).format(date);
}

/**
 *
 * @param jsonString string to validate if contains a valid json object
 * @returns json object or null
 */
// eslint-disable-next-line
export function validateJsonStringified(jsonString: string): any {
  // eslint-disable-next-line
  let json: any = null;

  try {
    json = JSON.parse(jsonString);
  } catch (error) {
    console.error(`error: ${error.message}`);
  }

  return json;
}

/**
 * parses link header into a map
 * @param linkHeader link header string with format <url>; rel=""
 * @returns map containing all links
 */
export function linkParser(linkHeader: string): Map<string, PaginationLink> {
  // eslint-disable-next-line
  let re = /<([^\?]+\?[a-z]+=([\d]+).+?)>;[\s]*rel="([a-z]+)"/g;
  let arrRes = [];
  const map = new Map();
  while ((arrRes = re.exec(linkHeader)) !== null) {
    map.set(arrRes[3], {
      url: arrRes[1],
      page: arrRes[2],
    });
  }
  return map;
}

/**
 * transforms a string enum into a SelectItem array
 * @param enumme enum to transform
 * @returns SelectItem array using enum values as name
 */
export function enumToSelectItem(enumme: {
  [id: string]: string;
}): Array<SelectItem> {
  return Object.values(enumme).map((value, index) => {
    return { id: index, name: value };
  }) as Array<SelectItem>;
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
/**
 * Resolves when the Element is present in the DOM
 * @param dom source
 * @param selector css selector
 * @param isArray set to true if an array is needed
 * @returns Element or Array<Element> when it is ready
 */
export function onElementReady(
  dom: ShadowRoot,
  selector: string,
  isArray = false
): Promise<Element | Array<Element>> {
  return new Promise((resolve, reject) => {
    let count = 0;
    const waitForElement = () => {
      count += 1;
      const $element = isArray
        ? Array.from(dom.querySelectorAll(selector))
        : dom.querySelector(selector);
      const length = isArray ? ($element as Array<Element>).length : 1;
      if ($element && length > 0) {
        resolve($element);
      } else {
        if (count > 1000) {
          reject(`no element found using selector '${selector}'`);
        } else {
          window.requestAnimationFrame(waitForElement);
        }
      }
    };
    waitForElement();
  });
}
/**
 * @deprecated Please use getDomElement
 * Selects an anchor
 *
 * @param anchor
 * @param $parent
 * @returns
 */
export function selectAnchor(
  anchor: string,
  $parent: Document | HTMLElement = document
): HTMLElement {
  return getDomElement(anchor, $parent);
}

/**
 * Gets a DOM Element, default from document but you can specify a parent node as a second parameter
 * @param selector
 * @param $parent
 * @returns Document | HTMLElement
 */
export function getDomElement(
  selector: string,
  $parent: Document | HTMLElement = document
): HTMLElement {
  try {
    return $parent.querySelector(selector);
  } catch (err) {
    // console.error(err);
    throw "Not a valid selector";
  }
}

export function isMode(env: string): boolean {
  return getMode() === env;
}

// eslint-disable-next-line
export function scrambleArray(arr: Array<any>): Array<any> {
  // Using knuthfisheryates;
  let i, temp, j;
  const len = arr.length;
  for (i = 0; i < len; i++) {
    j = ~~(Math.random() * (i + 1));
    temp = arr[i];
    arr[i] = arr[j];
    arr[j] = temp;
  }
  return arr;
}

export const decodeHTMLEntities = (function () {
  // this prevents any overhead from creating the object each time
  const element = document.createElement("div");

  function decodeEntities(str: string) {
    if (str && typeof str === "string") {
      // strip script/html tags
      str = str.replace(/<script[^>]*>([\S\s]*?)<\/script>/gim, "");
      str = str.replace(/<\/?\w(?:[^"'>]|"[^"]*"|'[^']*')*>/gim, "");
      element.innerHTML = str;
      str = element.textContent;
      element.textContent = "";
    }

    return str;
  }

  return decodeEntities;
})();

export function replaceHyphenForCamelCase(str: string): string {
  return str
    .split("-")
    .map((part, idx) => {
      return idx === 0 ? part : capitalizeFirstLetter(part);
    })
    .join("");
}

/**
 * Helper that notifies given if element
 * @param selector string
 * @param returnNode boolean
 * @returns boolean or node
 */
export function waitForNodeInScreen(
  selector: string,
  returnNode = false
): Promise<boolean | Node> {
  return new Promise((resolve) => {
    if (document.querySelector(selector)) {
      return returnNode
        ? resolve(document.querySelector(selector))
        : resolve(true);
    }
    const observer = new MutationObserver(() => {
      if (document.querySelector(selector)) {
        observer.disconnect();
        return returnNode
          ? resolve(document.querySelector(selector))
          : resolve(true);
      }
    });

    observer.observe(document.querySelector("#app"), {
      childList: true,
      subtree: true,
    });
  });
}
